<template>
  <div>
    <page-title title="Create New bond Template" />
    <div class="row row-sm">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-8">
                <div class="form-group">
                  <label>Template Name</label>
                  <input type="text" class="form-control" v-model="name" placeholder="Name" />
                </div>
                <div class="form-group">
                  <label>Template Type</label>
                  <select v-model="bond_type" class="form-control">
                    <option value="bidbond">Bidbond</option>
                    <option value="performancebond">Performancebond</option>
                  </select>
                </div>
                <div class="form-group">
                  <tinymce
                    id="one"
                    htmlClass="large_editor"
                    :other_options="editor_options"
                    v-model="my_data"
                  />
                </div>
                <div class="form-group">
                  <a href="#" @click.prevent="submit" class="btn btn-secondary float-right">Save</a>
                </div>
              </div>
              <div class="col-md-4">
                <div class="variables">
                  <h4>What you can use</h4>
                  <table class="table ">
                    <tbody>
                      <tr v-for="(variable, definition) in variables" :key="`def-${definition}`">
                        <td>
                          <a
                            href="#"
                            :id="variable"
                            @click.prevent="copyVariable(variable)"
                            title="copy"
                            class="copy text-secondary"
                          >
                            <i class="lni-pencil"></i>
                          </a>
                          {{ definition }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tinymce from "vue-tinymce-editor";
/* eslint-disable */

export default {
  mounted() {
    this.init();
  },

  components: {
    tinymce
  },

  data() {
    return {
      my_data: {},
      editor_options: {
        height: "400px",
        file_picker_types: "image",
        /* and here's our custom image picker*/
        file_picker_callback: function(cb) {
          var input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("accept", "image/*");
          var _this = this;
          input.onchange = function() {
            var file = this.files[0];

            var reader = new FileReader();

            reader.onload = () => {
              var id = "blobid" + new Date().getTime();
              var blobCache = _this.editorUpload.blobCache;
              var base64 = reader.result.split(",")[1];
              var blobInfo = blobCache.create(id, file, base64);
              blobCache.add(blobInfo);

              cb(blobInfo.blobUri(), { title: file.name });
            };

            reader.readAsDataURL(file);
          };

          input.click();
        }
      },
      variables: [],
      name: "",
      error: "",
      bond_type: ''
    };
  },

  methods: {
    init() {
      this.$loader.start()
      this.$axios.get("/api/v1/dashboard/bond-templates/create").then(response => {
        this.variables = response.data;
        this.$loader.stop()
      });
    },

    copyVariable(val) {
      var to_copy = "{{ $" + val + " }}";
      var input = document.createElement("input");
      input.setAttribute("type", "text");
      input.setAttribute("value", to_copy);
      input.setAttribute("id", "temporary_input");
      var clicked = document.getElementById(val);
      clicked.prepend(input);
      input.focus();
      input.select();
      document.execCommand("copy");
      document.getElementById("temporary_input").remove();
      var msg = document.createElement("span");
      msg.setAttribute("id", "show_copied");
      msg.innerHTML = "Copied";
      clicked.append(msg);

      setTimeout(() => {
        msg.remove();
      }, 2000);
    },

    submit() {
      this.$loader.start()

      let data = {
        name: this.name,
        content: this.my_data,
        type: this.bond_type
      };

      this.$axios
        .post("/api/v1/dashboard/bond-templates", data)
        .then(response => {
          this.$loader.stop()
          this.$router.push({
            name: "bond-templates.show",
            params: { id: response.data.id }
          });
        })
        .catch(error => {
          this.$alert.httpError(error.respone);
        });
    }
  }
};
</script>

<style lang="scss">
.copy {
  i {
    margin-right: 10px;
  }
}

#show_copied {
  background: #404040;
  color: #fff;
  padding: 5px;
  top: 15px;
}
</style>